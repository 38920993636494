import sal from 'sal.js'
import 'sal.js/dist/sal.css'
import {Application} from '@hotwired/stimulus'
import {definitionsFromContext} from '@hotwired/stimulus-webpack-helpers'
import GalleryController from '../store/controllers/gallery_controller'


window.addEventListener('load', function () {
    sal({
        threshold: 0.6
    })
})

const Stimulus = Application.start()
const context = require.context('./controllers', true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

Stimulus.register('gallery', GalleryController)