import {Controller} from "@hotwired/stimulus";


export default class extends Controller {
    static targets = ['product']

    connect() {
        this.show()
    }

    show() {
        setTimeout(() => {
            this.productTarget.classList.add('show')
        }, 1000)
    }
}