import {Controller} from "@hotwired/stimulus";


export default class extends Controller {

    connect() {
        this.sticky()
    }

    sticky() {
        const observer = new IntersectionObserver(
            ([e]) => e.target.classList.toggle('header--sticky', e.intersectionRatio < 1),
            {threshold: [1]}
        )
        observer.observe(this.element)
    }
}