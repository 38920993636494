import {Controller} from "@hotwired/stimulus";


export default class extends Controller {

    connect() {
        RequestHandler.Form(this.element, {
            redirect: reverse('dashboard_selfpages:overview') + '?first=1',
            recaptcha: true,
        })
    }

}